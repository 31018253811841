import "react-loading-skeleton/dist/skeleton.css";

export const parseTextWithEnviromentVariables = (text, data, company) => {
  let result = text;
  for (const key in data) {
    const pattern = `{{${key}}}`;

    if (result.includes(pattern)) {
      const rgx = new RegExp(pattern, "g");
      result = result.replace(
        rgx,
        company && key !== "registry_city" ? data[`${key}_${company}`] : data[key]
      );
    }
  }
  return result;
};

export const generateMerchantsList = (companies, textKey, buildedSiteData) => {
  if (!companies) return;
  if (!buildedSiteData) return;
  
  return `<ul>${companies
    ?.map(
      (company) => {
        return `<li>${
          parseTextWithEnviromentVariables(
            getEnviromentVariable(textKey, buildedSiteData),
            buildedSiteData,
            company
          ) || ""
        }</li>`;
      
      }
    )
    .join("")}</ul>`;
};

export const getEnviromentVariable = (key, data) => {
  return typeof data[key] === "string" || data[key]
    ? data[key]
    : `<span class="react-loading-skeleton" style="width:150px">‌</span>`;
};
