import React, { useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { sendViewItemListEvent } from "../components/utils/dataLayer/events";
import { Layout } from "../components/Layout/layout";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import { Title } from "../components/atoms/Title/title";
import OurBenefits from "../components/Form/OurBenefits";
import CardsPassport from "../components/molecules/CardsPassport";
import StepsForm from "../components/Form/Steps";
import Steps from "../components/molecules/Steps";
import { cardsPassportData, ourBenefitsDataSvg, stepsData, stepsHowItWorks } from "../data/filling";
import { buildPassportCardsData } from "../components/utils/landingPages";
import { amplitudeTrackEvent } from "../components/utils/Amplitude";
import useSendDatalayer from "../hooks/useSendDatalayer";
import "../templates/home/styles.scss";

const HomeFillingTemplate = ({ data }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  useEffect(() => {
    amplitudeTrackEvent("view_landing_filling");
  }, []);

  const parsedCardsPassportData = useMemo(() => {
    const cards = cardsPassportData("default", language);
    return buildPassportCardsData(cards, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes, language]);

  useSendDatalayer(sendViewItemListEvent, ["DS-5504", "DS-64", "DS-11", "DS-82"]);

  return (
    <Layout>
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={t("Simplified Document Editing")}
        />
        <CardsPassport
          cards={parsedCardsPassportData}
          source="landing-filling"
        />
      </Wrapper>
      <OurBenefits
        data={ourBenefitsDataSvg}
        hasRichtext
      />
      <Wrapper>
        <StepsForm data={stepsData} />
      </Wrapper>
      <section
        id="how-it-works"
        className={`section section--how section--how-default`}>
        <Wrapper>
          <Steps
            title={t("How it works")}
            stepsArray={stepsHowItWorks}
            hasRichtext
          />
        </Wrapper>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: $language} }) {
      nodes {
        slug
      }
    }
  }
`;

export default HomeFillingTemplate;
