const babyPassport = {
  id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
  show: true,
  name: "DS-11",
  headerTitle: "Baby Passport DS-11",
  formButtonText: "Start U.S. Passport Application",
  formButtonTextb: "Start Form DS-11",
  metaDescription:
    "Complete the DS-11 application to apply for a new infant passport. Our online services make the application process easy and convenient.",
  slug: "/new-passport",
  governmentPdf: {
    file: {
      url: "//assets.ctfassets.net/otm3aqjb1k5c/R7NMTtMNNs1lma2g8wtnA/a62dcbbfd7da2a08a517125f3ca35014/ds11-ag.pdf",
    },
  },
  formImage: {
    id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
    fluid: {
      src: "/images/hero-passport-baby.png",
    },
  },
  type: false,
  description: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "To",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: " apply ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "for your ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: " baby’s U.S. passport",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: ", use our secure online tool to complete the DS-11 form.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Fill out the",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: " online form ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "without hassle, quickly and easily from your smartphone or computer.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "If your child’s passport is",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: " damaged or missing",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: ", you also need to get a new U.S. passport using the DS-11 form.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  requeriments: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "DS-11 Requirements for minors:",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "To submit a Form DS-11 for your child’s new passport, they must meet the following criteria: ",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "They are a baby.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "It is the first U.S. passport they are applying for.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Their previous U.S. passport was lost, damaged, or stolen.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "The following documentation must also accompany your application:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Proof of your baby’s U.S. citizenship.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Evidence of your parental relationship.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Valid form of your ID.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Parental consent.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "One photo of your infant.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "To prove your parental relationship or your child's citizenship, you must provide a ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "certified copy",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: " of any documentation, such as a certified copy of your birth certificate.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationOne: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "When to Apply for Your Baby’s New Passport (Form DS-11)",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Processing time for a baby’s passport normally takes ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "10 to 13 weeks.",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: " Prepare your baby’s DS-11 form and apply as soon as possible to ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "avoid any delays.",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "A passport is needed to enter other countries, even for children. Your child won’t be allowed to travel internationally without their passport.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationTwo: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "Need to Renew or Replace a U.S. Passport? ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "For new passport applications, you can only use Form DS-11. If this is your first time applying, or your last passport was damaged, this is the correct form.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "However, you must ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "use a different form ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "if:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You are renewing your passport because it is going to expire.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You plan to report a lost or stolen passport.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your passport expired less than 5 years ago.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "You can find forms for these types of applications on our website. Click the button below to access our different passport application forms. ",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  order: 2,
  orderByTemplate: {
    default: 2,
    template_1: 1,
  },
};

export default babyPassport;

export const ourBenefitsData = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared passport form and personalized filling instructions by using our online form and filling software",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Simple, intuitive application form",
  benefit2: "Comprehensive error checking",
  benefit3: "Easy step-by-step instructions",
  benefit4: "24/7 customer support",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "01",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Fill in the Form DS-11",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Follow our simple instructions and complete your DS-11 Form in minutes.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "02",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Print and send",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Submit the completed application form with the necessary identification, parental consent, and proof of your child’s citizenship.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "03",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Receive your child’s Passport",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value:
                      "After your baby’s passport is issued, it will be mailed to your home address.",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
