import React, { useEffect, useMemo } from "react";
import { sendViewItemListEvent } from "../components/utils/dataLayer/events";
import { Layout } from "../components/Layout/layout";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import { Title } from "../components/atoms/Title/title";
import OurBenefits from "../components/Form/OurBenefits";
import CardsPassport from "../components/molecules/CardsPassport";
import Steps from "../components/Form/Steps";
import { useStaticQuery, graphql } from "gatsby";
import { cardsPassportData, ourBenefitsData, stepsData } from "../data/filling-es";
import { buildPassportCardsData } from "../components/utils/landingPages";
import { amplitudeTrackEvent } from "../components/utils/Amplitude";
import useSendDatalayer from "../hooks/useSendDatalayer";

const HomeFillingTemplate = () => {
  const data = useStaticQuery(getContentfulData);

  const parsedCardsPassportData = useMemo(() => {
    const cards = cardsPassportData("default");
    return buildPassportCardsData(cards, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes]);

  useSendDatalayer(sendViewItemListEvent, ["DS-5504", "DS-64", "DS-11", "DS-82"]);

  useEffect(() => {
    amplitudeTrackEvent("view_landing_filling-es");
  }, []);

  return (
    <Layout>
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={"Edición simplificada de documentos"}
        />
        <CardsPassport
          cards={parsedCardsPassportData}
          source="landing-filling-es"
        />
      </Wrapper>
      <OurBenefits data={ourBenefitsData} />
      <Wrapper>
        <Steps data={stepsData} />
      </Wrapper>
    </Layout>
  );
};

const getContentfulData = graphql`
  query {
    allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: "es"} }) {
      nodes {
        slug
      }
    }
  }
`;

export default HomeFillingTemplate;
