import { scrollBehaviorTo, scrollY } from "./index";

export const getUsedFields = (step, form) => {
  const fields = [];

  step.groups.forEach((group) => {
    let checkGroup = true;

    if (group.reference) {
      const ref = group.reference;
      checkGroup = form[ref.name].value === ref.value;
    }

    if (checkGroup) {
      group.fields.forEach((fieldName) => {
        const isExist = fields.indexOf(fieldName) === -1;
        const checkField = checkFieldRef(fieldName, form);

        if (isExist && checkField) {
          fields.push(fieldName);
        }
      });
    }
  });

  return fields;
};

export const checkFieldRef = (fieldName, form) => {
  let validation = true;

  const ref = form[fieldName].reference;

  if (ref) {
    validation = form[ref.name].value === ref.value;
    if (typeof ref.value === "boolean") {
      validation = form[ref.name].value !== "";
    }
  }

  let emptyField = false;

  if (!form[fieldName].required) {
    emptyField = form[fieldName].value === "";
  }

  return validation ? validation !== emptyField : false;
};

export const buildFormToSubmit = (steps, form) => {
  let data = {
    pdfId: form.pdfId.value,
  };

  const fields = steps.reduce((lastFields, step) => {
    return [...lastFields, ...getUsedFields(step, form)];
  }, []);

  const hasAddonCard = form.addonsCard ? true : false;
  const addonCardItem = form.addons.value.filter((item) => item.identifier === "card");

  if (hasAddonCard) {
    fields.unshift("documentType");
    if (addonCardItem.length) {
      if (addonCardItem[0].value === "1") {
        form = { ...form, documentType: { ...form.documentType, value: "both" } };
      }
      if (addonCardItem[0].value === "0") {
        form = { ...form, documentType: { ...form.documentType, value: "passportbook" } };
      }
    }
  }

  fields.forEach((key) => {
    const field = form[key];

    data = {
      ...data,
      ...getValueToSubmit(field, form),
    };
  });

  return data;
};

const getValueToSubmit = (field, form) => {
  switch (field.name) {
    case "birthCountry": {
      return {
        birthPlace: buildBirthCountry(form),
        birthCountry: field.value,
      };
    }
    case "addons": {
      return { addons: buildAddons(field.value) };
    }
    case "passportBookLostType": {
      return {
        [field.targetName]: field.value,
        passportBookLostType: field.value,
      };
    }
    case "passportCardLostType": {
      return {
        [field.targetName]: field.value,
        passportCardStatus: field.value,
      };
    }
    default: {
      let data = {
        [field.name]: field.value,
      };

      if (field.value === "") {
        data[field.name] = "undefined";

        if (field.type === "date") {
          data[field.name] = null;
        }
      }

      return data;
    }
  }
};

const buildBirthCountry = (form) =>
  `${form.birthCity.value} ${
    form.birthCountry.value === "US" ? form.birthState.value : form.birthCountry.value
  }`;

const buildAddons = (addons) => {
  if (Array.isArray(addons)) {
    const filteredAddons = addons.filter(({ value }) => value === "1");
    return filteredAddons.map(({ id }) => id);
  }

  return [];
};

export const scrollToRef = (ref) => {
  if (document.getElementById(ref)) {
    scrollBehaviorTo(document.getElementById(ref).getBoundingClientRect().top + scrollY() - 110);
  }
};

export const changeFieldProperties = (fields = [], properties, form) => {
  let newForm = {};

  fields.forEach((fieldName) => {
    if (!newForm[fieldName]) {
      newForm[fieldName] = { ...form[fieldName] };
    }

    for (const key in properties) {
      const value = properties[key];

      if (typeof value === "function") {
        newForm[fieldName][key] = value(form[fieldName][key]);
      } else {
        newForm[fieldName][key] = value;
      }
    }
  });

  return newForm;
};
