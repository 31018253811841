import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { getCookie } from "../../components/utils/cookies";
import { sendViewItemListEvent } from "../../components/utils/dataLayer/events";
import { Layout } from "../../components/Layout/layout";
import { Helmet } from "react-helmet";
import Billboard from "../../components/organisms/Billboard";
import OurBenefits from "../../components/Form/OurBenefits";
import { ourBenefitsData } from "../../data/filling";
import Links from "../../components/molecules/Links";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import List from "../../components/molecules/List";
import Steps from "../../components/molecules/Steps";
import { ReactSVG } from "react-svg";
import Button from "../../components/atoms/Button";
import Carousel, { CarouselItem } from "../../components/molecules/Carousel";
import Testimonial from "../../components/molecules/Testimonial";
import useSendDatalayer from "../../hooks/useSendDatalayer";
import {
  data,
  links,
  features,
  faqs,
  steps,
  testimonials,
  testimonialsCities,
} from "../../data/state";
import "./styles.scss";
import { graphql } from "gatsby";

const doneIcon = (
  <ReactSVG
    src="/svg/icon-done.svg"
    className="List__icon"
  />
);

const createTestimonialsListWithCities = (state) => {
  const newTestimonials = [...testimonials];

  newTestimonials.map(
    (testimonial, index) => (testimonial["city"] = testimonialsCities[state][index])
  );

  return newTestimonials;
};

const StateTemplate = ({ pageContext }) => {
  const { t } = useTranslation();
  const { domain } = getCookie("site_info");

  const testimonialsWithCities = createTestimonialsListWithCities(pageContext.id);
  const stateName = pageContext.name.includes("-")
    ? pageContext.name.split("-").join(" ")
    : pageContext.name;

  useSendDatalayer(sendViewItemListEvent, ["DS-64", "DS-11", "DS-82"]);

  return (
    <Layout
      headerTitle={t(data.headerTitle)}
      headerLanding>
      <Helmet>
        <title>
          {t(data.headerTitle)} - {t(pageContext.name)}
        </title>
        <meta
          name="description"
          content={data.metadesc}
        />
      </Helmet>

      <Billboard
        backgroundImageUrl={data.billaboradBg}
        stateAbbreviation={pageContext.id}
        title={`${stateName} ${t(data.billaboradH1)}`}
        subtitle={t(data.billaboradSubtitle)}
        component={
          <Links
            LinksArray={links}
            FooterLink={{ text: data.ctaText, url: data.ctaUrl }}
          />
        }
      />

      <OurBenefits
        template="default"
        showHeader={false}
        className="our-benefits--banner"
        data={ourBenefitsData}
      />
      <div className="state__section">
        <Wrapper classNames="state__wrapper">
          {
            <Steps
              title={t(data.stepsTitle)}
              stepsArray={steps}
            />
          }
        </Wrapper>
      </div>

      <div className="state__section">
        <Wrapper classNames="state__wrapper state__wrapper--flexend">
          <div className="state__half">
            {
              <List
                title={data.listTitle}
                list={features}
                icon={doneIcon}
              />
            }
          </div>
          <div className="state__half">
            <Links
              LinksArray={links}
              FooterLink={{ text: data.ctaText, url: data.ctaUrl }}
            />
          </div>
        </Wrapper>
      </div>

      <div className="state__section">
        <Wrapper classNames="state__wrapper state__wrapper--center">
          <div className="state__half">
            <Carousel>
              {testimonialsWithCities.map((item, index) => (
                <CarouselItem key={`Carousel-item-${index}`}>
                  <Testimonial
                    testimonial={item}
                    avatar
                  />
                </CarouselItem>
              ))}
            </Carousel>
          </div>
          <div className="state__half">
            {
              <List
                title={t(data.faqTitle)}
                list={faqs(domain)}
                button={
                  <Button
                    primary
                    label={t(data.faqLinkText)}
                    link={data.faqLinkUrl}
                    dataQa={`landing-state-${pageContext.name}`}
                  />
                }
              />
            }
          </div>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default StateTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;