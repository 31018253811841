const newpassport = {
  id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
  show: true,
  name: "DS-11",
  headerTitle: "New Passport Form DS-11",
  formButtonText: "Start U.S. Passport Application",
  formButtonTextb: " Start Form DS-11",
  metaDescription:
    "Complete the Form DS-11 to apply for a new U.S. passport. It’s quick and easy to fill out your passport application form using our online services.",
  slug: "/new-passport",
  governmentPdf: {
    file: {
      url: "//assets.ctfassets.net/otm3aqjb1k5c/R7NMTtMNNs1lma2g8wtnA/a62dcbbfd7da2a08a517125f3ca35014/ds11-ag.pdf",
    },
  },
  formImage: {
    id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
    fluid: {
      src: "/images/form-landing-DS-11.png",
    },
  },
  type: false,
  description: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "To apply for a new U.S. passport, you need to ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "complete the DS-11 form. ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value:
                "If it’s your first time applying for a U.S. passport, or your passport was damaged, this is the essential first step.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Our secure online tool will help you fill out your DS-11 passport form ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "in minutes.",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  requeriments: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "DS-11 Requirements:",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "You can apply for a new passport using the Form DS-11 in the following situations:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "It is your first U.S. passport application.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your current U.S. passport has been damaged, lost or stolen.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your previous U.S passport was issued over 15 years ago.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You are under the age of 16.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your last passport was issued when you were under the age of 16.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "Note that you cannot apply for a passport using Form DS-11 if you already have a passport that is about to expire, or expired in the last 5 years. If this is the case you should ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "renew ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "your U.S. passport.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationOne: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "When to Apply for a New Passport (Form DS-11)",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "U.S. passport processing takes ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "8 to 11 weeks. ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value:
                "Avoid travel disruption by applying for a passport as soon as possible before your trip. ",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "A passport is essential if you want to ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "travel internationally. ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value:
                "If you do not apply for your passport in time, you will not be able to enjoy overseas vacations or business trips. ",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "Our online service allows you to quickly complete the DS-11 form without complications.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationTwo: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "Need a Different Passport Application Form?",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Form DS-11 is only for ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "new passport applications.",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value:
                " If this is your first time applying for a passport, or your old document was damaged, this is the correct form for you.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "You need a different type of passport form if:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You are renewing a passport that is about to expire.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your passport expired less than 5 years ago.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You need to report a lost or stolen passport.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "These documents are ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "also available on our website. ",
              marks: [{ type: "bold" }],
              data: {},
            },
            {
              nodeType: "text",
              value: "Click the button below to access our other passport application forms.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  order: 2,
  orderByTemplate: {
    default: 2,
    template_1: 1,
  },
};

export default newpassport;

export const ourBenefitsData = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared passport form and personalized filling instructions by using our online form and filling software",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Simple, intuitive application form",
  benefit2: "Comprehensive error checking",
  benefit3: "Easy step-by-step instructions",
  benefit4: "24/7 customer support",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "01",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Fill in the Form DS-11",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Follow our step-by-step instructions and successfully complete your DS-11 Form.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "02",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Print and submit",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Submit your completed application form, evidence of citizenship, photocopy of ID, and photo at a passport acceptance facility.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "03",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Receive Your Passport",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value:
                      "You’ll be sent your new passport by mail after your application is approved.",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
