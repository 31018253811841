const changesCorrections = {
  id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
  show: true,
  name: "DS-5504",
  headerTitle: "Easy Passport Update with Form DS-5504",
  formButtonText: "Start Form DS-5504",
  formButtonTextb: "Start Form DS-5504",
  metaDescription:
    "Form DS-5504 is vital if your passport is expiring and needs replacing. Complete a passport renewal form safely and securely online now before you travel.",
  slug: "/passport-corrections",
  governmentPdf: {
    file: {
      url: "https://assets.ctfassets.net/otm3aqjb1k5c/1Aoegzt0xZ4aSNEuedNWDu/9e5c419b2dd3f8152948bf205762cda3/ds5504.pdf",
    },
  },
  formImage: {
    id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
    fluid: {
      src: "/images/form-landing-DS-5504.png",
    },
  },
  type: false,
  description: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Form DS-5504 is designed for U.S. passport holders who need to ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "update or correct their passports ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "without renewing them.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Ideal for name changes, correcting a printing error, or ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "updating a photo",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: ".",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "Update your passport as smoothly and straightforwardly as possible with our support.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  requeriments: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "Requirements and Instructions",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "To submit Form DS-5504 you must meet the following criteria:",
              marks: [],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your passport has a ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "printing error ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "that occured within a year of its issuing date.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your passport is in ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "good condition",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: ".",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "You have documentation to ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "support the change ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "or correction you are requesting.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "You have a “Limited Validity Passport”.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Mail Your Application: ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "Send your completed form along with the following documents:",
              marks: [],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "Sign and date ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "your application.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "Include a 2″ x 2″ (5 cm x 5 cm) ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "color photo ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "with a white background.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value: "Include the U.S. passport you are updating.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "If you are changing your name:",
              marks: [],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "Provide a certified copy or original copy of the U.S. legal name change document. ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "Example: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value:
                        "U.S. marriage certificate, U.S. divorce decree, U.S. court-ordered name change document.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Submit Without a Fee: ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value:
                "There is no charge for processing Form DS-5504 if your passport was issued less than a year ago.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationOne: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "When to Apply",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "For Corrections: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "As soon as you notice an error in your passport.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "For Name Changes: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "After receiving your legal name change documentation.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "For Extensions: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "Before the expiration of your limited-validity passport.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationTwo: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "Why Choose Our Service?",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Expert Guidance: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value:
                        "Our team provides comprehensive support and guidance through the application process.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Error-Free Applications: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value: "We help ensure your application is accurate and complete.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Timely Updates: ",
                      marks: [
                        {
                          type: "bold",
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: "text",
                      value:
                        "Stay informed about your application status with our tracking system.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationThree: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "You have other passport needs?",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "Form DS-5504 is to update or correct a passport. You don’t need to make any changes to your current passport?",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Perhaps ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "you never had a passport before ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "or you simply need to renew your passport.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "For each case, you need a different application form.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "You need a different type of passport form if:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You need a new passport",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your passport expired less than 5 years ago.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You need to report a lost or stolen passport.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "These forms are ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "available on our website",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: ". Click the button below to access our other passport application forms.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  getStartedCopy:
    "<span><b>Ready to update your passport with Form DS-5504? </b></span><span>Begin your hassle-free application process with us.</span> <span>For more information, visit or contact our <a href='/contact'>customer support team.</a></span>",
  order: 2,
  orderByTemplate: {
    default: 2,
    template_1: 1,
  },
};

export default changesCorrections;

export const ourBenefitsData = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared passport form and personalized filling instructions by using our online form and filling software",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Simple, intuitive application form",
  benefit2: "Comprehensive error checking",
  benefit3: "Easy step-by-step instructions",
  benefit4: "24/7 customer support",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "01",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Fill out form DS-5504",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Fill out our user-friendly form and finish in just a few minutes.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "02",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Print and mail",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Submit your completed application form, passport page photocopy, and photo at a passport acceptance facility.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "03",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Get your new passport",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value:
                      "Your passport will be delivered by mail once your application is approved.",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
