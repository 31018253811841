import { init, track, setUserId, add, identify, Identify } from "@amplitude/analytics-browser";
import { webAttributionPlugin } from "@amplitude/plugin-web-attribution-browser";
import { windowIsExist } from "../index";
import UAParser from "ua-parser-js";

export const initAmplitude = () => {
  const amplitudeKey = process.env.AMPLITUDE_API_KEY || null;

  if (!amplitudeKey || !windowIsExist) return;

  add(webAttributionPlugin());

  init(amplitudeKey, undefined, {
    defaultTracking: true,
    serverZone: "EU",
  });

  amplitudeDeviceType();
};

export const amplitudeDeviceType = () => {
  const parser = new UAParser();
  const userAgent = parser.getResult();

  let deviceType = "Unknown";
  if (userAgent.device.type === "mobile") {
    deviceType = "Mobile";
  } else if (userAgent.device.type === "tablet") {
    deviceType = "Tablet";
  } else {
    deviceType = "Desktop";
  }

  const identifyEvent = new Identify();
  identifyEvent.set("User Device", deviceType);
  identify(identifyEvent);
};

export const amplitudeTrackUser = (userId) => {
  if (process.env.GATSBY_ENV === "local") return;
  setUserId(userId);
};

export const amplitudeTrackEvent = (name, data) => {
  if (process.env.GATSBY_ENV === "local") return;
  track(name, data);
};
