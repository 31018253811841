import React from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";

const HomeLayout = ({
  children,
  plainHeader,
  headerTitle,
  jumbotronTitle,
  hideFooterNavigationLogo,
  footerTemplate,
}) => (
  <Layout
    plainHeader={plainHeader}
    headerTitle={headerTitle}
    hideFooterNavigationLogo={hideFooterNavigationLogo}
    footerTemplate={footerTemplate}
    collapsedMobileTest>
    {jumbotronTitle && (
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={jumbotronTitle}
        />
      </Wrapper>
    )}
    {children}
  </Layout>
);

export default HomeLayout;
