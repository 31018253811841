import React, { createContext } from "react";
import useGetSiteInfo from "../hooks/useGetSiteInfo";
import useSiteData from "../hooks/useSiteData";

const SiteDataContext = createContext({});

export const SiteDataContextProvider = ({ children }) => {
  useGetSiteInfo();
  const siteData = useSiteData();
  return <SiteDataContext.Provider value={siteData}>{children}</SiteDataContext.Provider>;
};

export default SiteDataContext;
