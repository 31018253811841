import React, { useContext } from "react";
import SiteDataContext from "../../../context/SiteDataContext";
import RichText from "../../organisms/RichText/richText";
import usePrepareClasses from "./hooks/usePrepareClasses";
import(`./steps.scss`);

const Steps = ({ data, template, className, ...props }) => {
  const { classnames } = usePrepareClasses({ template, className, ...props });
  const siteData = useContext(SiteDataContext);

  return (
    <div className="steps">
      <ul className={classnames}>
        {data.edges.map((item, index) => {
          return (
            <li
              key={`Step-${index}`}
              className={`steps__item medium`}>
              <RichText
                content={item.node.stepSvg}
                siteData={siteData}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Steps;
