import React, { useMemo, useContext, useEffect } from "react";
import SiteDataContext from "../../context/SiteDataContext";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import Button from "../../components/atoms/Button";
import LinkWithSVG from "../../components/atoms/LinkWithSVG";
import OurBenefits from "../../components/Form/OurBenefits";
import Steps from "../../components/Form/Steps";
import RichText from "../../components/organisms/RichText/richText";
import productData, { ourBenefitsData, stepsData } from "../../data/child-passport";
import Seo from "../../components/utils/SEO";
import PreviewDocument from "../../components/molecules/PreviewDocuments";
import { useStaticQuery, graphql } from "gatsby";
import { buildPassportCardData } from "../../components/utils/landingPages";
import { sendViewItemEvent } from "../../components/utils/dataLayer/events";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import useSendDatalayer from "../../hooks/useSendDatalayer";
import * as styles from "./child-passport.module.scss";

const HomeChildPassport = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFormType(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
          name
        }
      }
    }
  `);

  const parsedProductData = useMemo(() => {
    return buildPassportCardData(productData, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes]);

  const dataQaId = useMemo(() => productData.name.slice(-2), []);

  useSendDatalayer(sendViewItemEvent, parsedProductData.name);

  const siteData = useContext(SiteDataContext);

  useEffect(() => {
    amplitudeTrackEvent("view_landing_childPassport");
  }, []);

  return (
    <Layout
      className={styles.wrapper}
      footerTemplate="template_1">
      <Seo
        title={parsedProductData.headerTitle}
        description={parsedProductData.metaDescription}
      />

      <Wrapper classNames={styles.header}>
        <div className={styles.header_left}>
          <Title
            regular
            content={"Simplified Document Editing for Passport Forms"}
            className={styles.header_subtitle}
          />
          <Title
            hero
            tag="h1"
            content={parsedProductData.headerTitle}
            className={styles.header_title}
          />
          <RichText
            content={parsedProductData.description}
            siteData={siteData}
          />
          <div className={styles.header_action}>
            <Button
              className={styles.header_action_button}
              label={parsedProductData.formButtonText}
              primary
              link={parsedProductData.slug}
              source="landing-childpassport"
              dataQa={`home-${dataQaId}`}
              full
            />
            <PreviewDocument
              fileUrl={parsedProductData.governmentPdf.file.url}
              Component={(props) => (
                <LinkWithSVG
                  className={styles.header_action_link}
                  svgSrc="/svg/shape.svg"
                  label="Preview document"
                  iconRight
                  secondary
                  {...props}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.header_image}>
          <img
            src={parsedProductData.formImage.fluid.src}
            alt={parsedProductData.headerTitle}
          />
        </div>
      </Wrapper>

      <div className={styles.body}>
        <Wrapper paddingTop>
          <Steps
            data={stepsData}
            template="default"
            secondary
            className={styles.steps__landing}
          />
        </Wrapper>
        <Wrapper paddingTop>
          <div className={styles.body_content}>
            <div className={styles.body_content_flex}>
              <RichText
                content={parsedProductData.informationOne}
                siteData={siteData}
                customClass={styles.body_content_child}
              />

              <RichText
                content={parsedProductData.requeriments}
                siteData={siteData}
                customClass={styles.body_content_child}
              />
            </div>
            <Button
              label={parsedProductData.formButtonTextb}
              secondary
              link={parsedProductData.slug}
              source="landing-childpassport"
              dataQa={`home-${dataQaId}`}
              className={styles.body_content_button}
            />
          </div>
        </Wrapper>
        <Wrapper>
          <div className={styles.info_block}>
            <RichText
              content={parsedProductData.informationTwo}
              siteData={siteData}
            />
            <Button
              label="View Other Forms"
              secondary
              link="/home"
              className={styles.info_block_button}
            />
          </div>
        </Wrapper>
      </div>
      <OurBenefits
        className={styles.ourBenefits}
        data={ourBenefitsData}
        template="default"
      />
    </Layout>
  );
};

export default HomeChildPassport;
