import React from "react";
import { ReactSVG } from "react-svg";
import "./styles.scss";

const Testimonial = ({ testimonial, starRate, avatar, nameOnBottom }) => {
  return (
    <div className="Testimonial u-box">
      {starRate &&
        [...Array(starRate)].map((e, i) => (
          <ReactSVG
            key={i}
            src="/svg/star.svg"
            className="Testimonial__star"
          />
        ))}
      {avatar && (
        <div className="Testimonial__avatar">
          <img
            src={testimonial.src}
            alt={testimonial.name}
          />
        </div>
      )}
      <p className="Testimonial__text">"{testimonial.text}"</p>
      {nameOnBottom ? (
        <div className="Testimonial__details">
          {testimonial.name} -<b> {testimonial.city}</b>
        </div>
      ) : (
        <>
          <div className="Testimonial__name">{testimonial.name}</div>
          <p className="Testimonial__state">{testimonial.city}</p>
        </>
      )}
    </div>
  );
};

export default Testimonial;
