import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../components/Layout/layout";
import { amplitudeTrackEvent } from "../components/utils/Amplitude";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import Loading from "../components/atoms/Loading";
import Seo from "../components/utils/SEO";
import { SideSummary } from "../components/organisms/SideSummary/";
import { MessageBox } from "../components/molecules/MessageBox";
import success from "../images/icons/done_sucess.svg";
import { getCookie, removeCookie } from "../components/utils/cookies";
import { sendPurchaseEvent } from "../components/utils/dataLayer/events";
import { graphql, navigate } from "gatsby";

const ThankYou = () => {
  const { t } = useTranslation();
  const orderCookie = getCookie("address_token");

  const [state, setState] = useState({
    orderReference: orderCookie.reference,
    applicationId: orderCookie.order,
    form: null,
    loading: true,
    application: {
      applicationName: "",
      applicationNumber: "",
      applicationPrice: {
        id: "",
        name: "",
        amount: "",
      },
    },
  });

  const getApplicationData = () => {
    const orderCookie = getCookie("address_token");

    let form = { ...state.application };
    form.applicationName = orderCookie.application.formName;
    form.applicationNumber = orderCookie.application.formDescription;
    form.applicationId = state.applicationId;
    form.applicationPrice = {
      name: orderCookie.regularPrice.name,
      amount: orderCookie.regularPrice.amount,
    };

    setState((prevState) => ({
      ...prevState,
      application: form,
      loading: false,
    }));
  };

  const pushDataLayer = (total, addons) => {
    if (typeof window !== "undefined") {
      sendPurchaseEvent(
        state.application.applicationName,
        state.orderReference,
        addons,
        state.application.applicationPrice.amount,
        total
      );
    } else {
      pushDataLayer(total, addons);
    }
  };

  const cleanCookies = () => {
    getApplicationData();

    removeCookie("address_token");
    removeCookie("pdfId");
    removeCookie("app_id");
    removeCookie("order_status");
  };

  useEffect(() => {
    if (state.applicationId === undefined || state.applicationId === "") {
      navigate("/home");
    }

    cleanCookies(state.applicationId);

    // Keep this event here, in the same order after cleanCookies function,
    // otherwise it will not send the form data
    amplitudeTrackEvent("view_page_thankYou", {
      form: state.application.applicationName.toLowerCase(),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.applicationId]);

  const setTotal = (total, addons) => {
    pushDataLayer(total, addons);
  }

  const application = {
    ...state.application,
    applicationReference: state.orderReference,
  };

  const applicationTitle =
    application && !state.loading
      ? t(`${application.applicationNumber} - ${application.applicationName} Form`)
      : undefined;

  return (
    <Layout
      headerTitle={t(applicationTitle)}
      bgColor={`bg-grey`}
      hideFooterNavigation={true}
      hideLanguageSelector={true}>
      <Seo title={t("Thank you for trusting us")} />
      <Wrapper small>
        {state.loading ? (
          <Loading full />
        ) : (
          <>
            <MessageBox
              title={t("Your payment has been made successfully!")}
              content={
                <>
                  <p>{t("Your application is now being processed.")}</p>
                  <p>
                    {t("In the next 24-72 hours, you will receive an email with confirmation of your payment and a further email containing a PDF of your completed ")}
                    {t(`${application.applicationName} application form.`)}
                  </p>
                  <p>
                    {t("If you have yet to receive this email and/or are unable to locate it after this time, please be sure you check your spam folder.")}
                  </p>
                </>
              }
              icon={success}
            />

            <SideSummary
              application={application}
              applicationReference={state.orderReference}
              applicationName={application.applicationNumber}
              applicationPrice={application.applicationPrice.amount}
              applicationID={application.applicationName}
              setTotal={(total, addons) => setTotal(total, addons)}
              thankYou
              isOpen={true}
            />
          </>
        )}
      </Wrapper>
    </Layout>
  );
}

export default ThankYou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;