import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Button from "../../../../atoms/Button";
import Check from "../../../../molecules/Check";
import useGetSiteMetaData from "../hooks/useGetSiteMetaData";
import data from "../../../../../data/payment.json";
import { GetTransactionInfoHandler } from "../../../../../services/api/GetTransactionInfo/GetTransactionInfoHandler";
import { setCookies } from "../../../../utils/cookies";
import "./styles.scss";

const element_id = "macropayjs";
const sdk_url = "https://hosted.macropay.com/sdk/v0/macropay.min.js";

const fields = data.fields;
// si ha cargado este file es porque la llamada a payment-details ha respondido con success y con el PSP asignado= Macropay

const MacropayPSP = ({
  paymentGatewayDetails, // nunca estará vacio porque llega aqui solo si la request de la request success
  setState,
  gateway,
  fieldChecked,
  buttonDisabled,
  loadIframeUrl,
}) => {
  const { t } = useTranslation();

  const [macropay, setMacropay] = useState(null);

  const {
    config: {
      payment: { showAgreeTermsAndCondition },
    },
  } = useGetSiteMetaData();

  const onLoad = () => {
    const config = {
      orderId: paymentGatewayDetails.result.orderId,
      apiKey: paymentGatewayDetails.apiKey,
      env: paymentGatewayDetails.env,
      version: 2,
    };

    const formOptions = {
      showButton: false,
      showCardTypes: false,
      showZipCode: paymentGatewayDetails.zipCodeField,
      styles: {
        input: {
          color: "#1f2022",
          borderRadius: "5px",
          border: "1px solid #6d7275",
          fontSize: 20,
          height: 30,
        },
        inputLabel: { color: "#1E2021", fontSize: 18 },
        inputLabelFocus: { color: "#1E2021" },
        inputHover: { borderColor: "#364abd" },
        inputValidation: { borderColor: "#00b886" },
        inputError: { borderColor: "#d63837" },
        inputFocus: { borderColor: "#364abd" },
      },
    };

    /* eslint-disable-next-line */
    const macropay = new Macropay(config);
    setMacropay(macropay);

    macropay.initForm({
      containerId: "m-macropay",
      onChange: (result) => {
        setState({ formButtonDisable: !result.isValid });
      },
      formOptions,
    });
  };

  const buildFormFromResponse = (response) => {
    const form = document.createElement("form");
    form.setAttribute("id", "challenge");
    form.setAttribute("name", "challenge");
    form.setAttribute("action", response.url);
    form.setAttribute("method", response.method);
    form.setAttribute("target", "m-macropay__threeds-iframe");

    response.parameters.forEach((parameter) => {
      const formInput = document.createElement("input");
      form.setAttribute("type", "hidden");
      formInput.setAttribute("name", parameter.key);
      formInput.setAttribute("value", parameter.value);
      form.appendChild(formInput);
    });

    const formSubmitButton = document.createElement("button");
    formSubmitButton.setAttribute("id", "macropayFormSubmitButton");
    formSubmitButton.setAttribute("type", "submit");
    form.appendChild(formSubmitButton);

    const iframeContainer = document.getElementById("m-macropay__threeds-iframe");
    const formContainer = document.getElementById("m-macropay__form");
    formContainer.innerHTML = "";
    iframeContainer.appendChild(form);
    document.forms["challenge"].submit();
    iframeContainer.classList.remove("u-display-none");
  };

  const macropaySendForm = useCallback(
    (gatewayIdentifier) => {
      setState({ loading: true, formButtonDisable: true });
      setCookies("address_token", { gateway: gatewayIdentifier });

      macropay
        .submit()
        .then((response) => {
          switch (response.transactionStatus) {
            case "success": // en caso de success se carga el iframe de verify payment que reenviará  la /thank-you
              loadIframeUrl("/verify-payment", response.transactionId);
              break;
            case "in_process":
              setState({ isSubmitting: true });
              statusPayment(response.transactionId); //ejecuta funcion de statusPayment para hacer un bucle de verificación
              break;
            case "waiting_user_interaction":
              setState({ loading: false, isSubmitting: false });
              if (response.action.method === "POST") {
                buildFormFromResponse(response.action);
              } else {
                loadIframeUrl(response.action.url, response.transactionId);
              }
              break;
            case "failed":
              macropayHandlerError(); // si es fallida refresca payment con ?error=true
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.error("Error =====> catch", err);
          macropayHandlerError();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [macropay]
  );

  const statusPayment = (transactionId, retries = 20) => {
    let data = {};
    let iframeLoaded = false;
    data.gatewayIdentifier = gateway;
    data.gatewayExternalId = transactionId;

    const GetTransactionInfo = new GetTransactionInfoHandler({});

    GetTransactionInfo.customAction(data)
      .then((res) => {
        if (res.transaction_status === "Success") {
          setState({ loading: false, formButtonDisable: false, isSubmitting: false });
          return loadIframeUrl("/verify-payment");
        } else if (res.transaction_status === "Failed") {
          macropayHandlerError();
        }

        if (res.transaction_status === "Pending" && res.redirectUrl3D) {
          loadIframeUrl(res.redirectUrl3D.url, transactionId);
          iframeLoaded = true;
        }

        if (!iframeLoaded) {
          if (retries > 0 && res.transaction_status === "Pending") {
            return setTimeout(function () {
              statusPayment(transactionId, retries - 1);
            }, 4000);
          } else {
            macropayHandlerError();
          }
        }
      })
      .catch((err) => {
        console.error("Error =====> catch", err);
        macropayHandlerError();
      });
  };

  const macropayHandlerError = () => {
    setState({ isSubmitting: false, errorPayment: true, loading: false });
    setTimeout(() => {
      window.top.location.href = "/payment?error=true"; //needs to refresh page, dont use navigate()
    }, 10);
  };

  useEffect(() => {
    if (paymentGatewayDetails && typeof document !== "undefined") {
      const script = document.createElement("script");
      script.src = sdk_url;
      script.setAttribute("id", element_id);
      document.body.appendChild(script);

      document.getElementById(element_id).addEventListener("load", onLoad);
    }

    return () => {
      if (typeof document !== "undefined") {
        const element = document.getElementById(element_id);
        if (element && paymentGatewayDetails) {
          element.removeEventListener("load", onLoad);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentGatewayDetails]);

  const [termsAndConditionInput, setTermAndConditionInput] = useState({
    value: fields.termsAndConditions.value,
  });

  const handleOnchageTermsAndCondition = () => {
    setTermAndConditionInput({
      ...termsAndConditionInput,
      value: termsAndConditionInput.value === "0" ? "1" : "0",
    });
  };

  return (
    <div
      className="m-blockForm"
      style={{
        display: `${fieldChecked ? "block" : "none"}`,
      }}>
      <iframe
        className="Pay__iframe u-display-none"
        src=""
        name="m-macropay__threeds-iframe"
        id="m-macropay__threeds-iframe"
        title="m-macropay__threeds-iframe"></iframe>
      <div id="m-macropay__form">
        <div id="m-macropay"></div>
        <div
          id="m-macropay__button"
          className="Pay-item--input-cta--macropay">
          <Button
            onClick={() => {
              macropaySendForm(gateway);
            }}
            disabled={buttonDisabled}
            primary
            dataQa="complete-order"
            dataAnl="mainButton"
            label={t("Complete your order")}
          />
        </div>
        {showAgreeTermsAndCondition && (
          <div style={{ padding: "0 2.25rem", marginTop: "1.5rem" }}>
            <Check
              {...fields.termsAndConditions}
              value={termsAndConditionInput.value}
              checked={termsAndConditionInput.value}
              onChange={handleOnchageTermsAndCondition}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MacropayPSP;
