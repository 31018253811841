import React, { Component } from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout/layout";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import { Infobox } from "../components/molecules/Infobox/infobox";
import {
  StepBoxSingle,
  StepBoxGrid,
  StepBoxColumns,
} from "../components/organisms/StepBox/StepBox";
import { Helmet } from "react-helmet";

class InstructionsPageTemplate extends Component {
  render() {
    const page = this.props.data.contentfulInstructionsPage;

    return (
      <Layout
        headerTitle={page.title}
        headerFull={true}
        hideMenuButton={false}
        backgroundColor={"instructions"}>
        <Helmet>
          <title>{page.title}</title>
          <meta
            name="description"
            content={page.metadescription}
          />
        </Helmet>
        <div>
          <Wrapper paddingTop>
            <Infobox
              title={page.subtitle}
              content={page.description}
            />
            <StepBoxSingle
              title={page.stepOne}
              content={page.stepOneNote}
            />
            <StepBoxGrid
              title={page.stepTwo}
              content={page.stepOneNote}
              blocks={page.stepBlocks}
            />
            <StepBoxColumns
              title={page.stepThree}
              content={page.stepThreeText}
              note={page.stepThreeNote}
              agencyButton={page.agencyNearYou}
              linkButtonAgency={page.linkButtonAgency}
            />
          </Wrapper>
        </div>
      </Layout>
    );
  }
}

export default InstructionsPageTemplate;

export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    contentfulInstructionsPage(slug: { eq: $slug }, node_locale: { eq: $language }) {
      slug
      title
      id
      metadescription
      stepOne
      stepThree
      stepTwo
      subtitle
      description {
        raw
      }
      stepOneNote {
        raw
      }
      stepBlocks {
        title
        button
        content {
          raw
        }
        linkToPage {
          slug
        }
      }
      stepThreeText {
        raw
      }
      stepThreeNote {
        raw
      }

      agencyNearYou
      linkButtonAgency
    }
  }
`;
