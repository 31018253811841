export class GetSiteInfoReducer {
  reduce = (res) => {
    return {
      domain: res.body.domain,
      webFulName: res.body.webFulName,
      hasChat: res.body.hasChat,
      chatActive: res.body.chatActive,
      hasPhone: res.body.hasPhone,
      companyPhoneNumber: res.body.phoneNumber,
      companyKey: res.body.extraInfo.key,
      gtm: res.body.gtm,
      vwo: res.body.metadata ? (res.body.metadata.vwo ? res.body.metadata.vwo : null) : null,
      showSpecialNMIInfo: res.body.metadata
        ? res.body.metadata.showSpecialNMIInfo
          ? res.body.metadata.showSpecialNMIInfo
          : null
        : null,
      chatWidgetId: res.body.metadata
        ? res.body.metadata.chatWidgetId
          ? res.body.metadata.chatWidgetId
          : null
        : null,
      termsAndConditionsCompanies: res.body.metadata
        ? res.body.metadata.termsAndConditions
          ? res.body.metadata.termsAndConditions
          : null
        : null,
      cashflowsDisclaimer: res.body.metadata
        ? res.body.metadata.cashflowsDisclaimer
          ? res.body.metadata.cashflowsDisclaimer
          : null
        : null,
      webPrice: res.body.formAmount,
      siteAddonsActive: res.body.addons,
      addonPriceInsurance: res.body.addons.some((addon) => addon.identifier === "insurance")
        ? res.body.addons.filter((addon) => addon.identifier === "insurance")[0].amount
        : null,
    };
  };
}
