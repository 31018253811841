export const data = {
  headerTitle: "Create a Passport Form in 5 Minutes",
  metadesc: "Simplified Document Editing for Passport Forms",
  billaboradBg: "../../../images/billboard_bg.jpg",
  billaboradH1: "Passport Forms",
  billaboradSubtitle:
    "Apply now and get your Passport in record time without moving from your chair",
  ctaText: "See all information about requirements",
  ctaUrl: "/requirements",
  stepsTitle: "How it works",
  listTitle: "What is included in our application package?",
  faqTitle: "Frequently Asked Questions",
  faqLinkText: "See More FAQs",
  faqLinkUrl: "/faq",
};

export const links = [
  { label: "DS-82", text: "Passport Renewal", url: "/passport-renewal" },
  { label: "DS-11", text: "New Passport", url: "/new-passport" },
  { label: "DS-64", text: "Lost or Stolen Passport", url: "/lost-stolen-passport" },
];

export const features = [
  "Passport Card without any extra cost",
  "Simple, intuitive application form",
  "Edit information for free",
  "Comprehensive error checking",
  "Easy step-by-step instructions",
  "24/7 expert support",
];

export const faqs = (siteName) => [
  "Who needs a valid U.S. passport? ",
  "How long does it take to get a passport?",
  `How does ${siteName} work?`,
  `What are the advantages of applying through ${siteName}?`,
  "How many blank visa pages are necessary to travel abroad?",
];

export const steps = [
  { title: "Select your form", text: "Choose the right type of Passport application." },
  { title: "Fill it out online", text: "Complete your document in 5 minutes." },
  { title: "Print and sign", text: "Easily download, print, and sign your form." },
  { title: "Submit your application", text: "Send your form in for processing." },
];

export const testimonials = [
  {
    src: "/images/testimonials/craig-harris.jpg",
    text: "Makes getting a passport so easy! Will definitely be sharing this service to friends and family.",
    name: "Craig Harris,",
  },
  {
    src: "/images/testimonials/sarah-carano.jpg",
    text: "Really happy with the service. Much faster than using government websites.",
    name: "Sarah Carano,",
  },
  {
    src: "/images/testimonials/brandon-felder.jpg",
    text: "Works like a charm. I love the simplicity of the website and the step-by-step instructions.",
    name: "Brandon Felder,",
  },
  {
    src: "/images/testimonials/gina-cortez.jpg",
    text: "Very fast and easy to use. I would recommend this service to anyone that needs to make a passport application.",
    name: "Gina Cortez,",
  },
];

export const testimonialsCities = {
  CA: ["Los Ángeles", "Sacramento", "San Francisco", "Long Beach"],
  AZ: ["Phoenix", "Tucson", "Mesa", "Chandler"],
  TX: ["Houston", "San Antonio", "Dallas", "Austin"],
  NY: ["Manhattan", "Queens", "Buffalo", "Rochester"],
  FL: ["Miami", "Jacksonville", "Tampa", "Orlando"],
  PA: ["Philadelphia", "Pittsburgh", "Allentown", "Allentown"],
  IL: ["Chicago", "Aurora", "Naperville", "Joliet"],
  GA: ["Atlanta", "Columbus", "Augusta", "Savannah"],
  WA: ["Seattle", "Spokane", "Tacoma", "Vancouver"],
};
