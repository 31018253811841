import React, { useMemo, useContext, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import SiteDataContext from "../../context/SiteDataContext";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import Button from "../../components/atoms/Button";
import LinkWithSVG from "../../components/atoms/LinkWithSVG";
import OurBenefits from "../../components/Form/OurBenefits";
import Steps from "../../components/Form/Steps";
import RichText from "../../components/organisms/RichText/richText";
import productData, { ourBenefitsData, stepsData } from "../../data/newpassport";
import Seo from "../../components/utils/SEO";
import PreviewDocument from "../../components/molecules/PreviewDocuments";
import { buildPassportCardData } from "../../components/utils/landingPages";
import { sendViewItemEvent } from "../../components/utils/dataLayer/events";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import useSendDatalayer from "../../hooks/useSendDatalayer";
import * as styles from "./newport.module.scss";

const HomeNewpassport = ({data}) => {
  const { t } = useTranslation();
  const parsedProductData = useMemo(() => {
    return buildPassportCardData(productData, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes]);

  const dataQaId = useMemo(() => productData.name.slice(-2), []);
  const siteData = useContext(SiteDataContext);

  useSendDatalayer(sendViewItemEvent, parsedProductData.name);

  useEffect(() => {
    amplitudeTrackEvent("view_landing_newPassport");
  }, []);

  return (
    <Layout
      className={styles.wrapper}
      footerTemplate="template_1">
      <Seo
        title={parsedProductData.headerTitle}
        description={parsedProductData.metaDescription}
      />

      <Wrapper classNames={styles.header}>
        <div className={styles.header_left}>
          <Title
            regular
            content={t("Simplified Document Editing for Passport Forms")}
            className={styles.header_subtitle}
          />

          <Title
            hero
            tag="h1"
            content={parsedProductData.headerTitle}
            className={styles.header_title}
          />

          <RichText
            content={parsedProductData.description}
            siteData={siteData}
          />

          <div className={styles.header_action}>
            <Button
              className={styles.header_action_button}
              label={parsedProductData.formButtonText}
              primary
              link={parsedProductData.slug}
              source="landing-newpassport"
              dataQa={`home-${dataQaId}`}
              full
            />
            <PreviewDocument
              fileUrl={parsedProductData.governmentPdf.file.url}
              Component={(props) => (
                <LinkWithSVG
                  className={styles.header_action_link}
                  svgSrc="/svg/shape.svg"
                  label={t("Preview document")}
                  iconRight
                  secondary
                  {...props}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.header_image}>
          <img
            src={parsedProductData.formImage.fluid.src}
            alt={parsedProductData.headerTitle}
          />
        </div>
      </Wrapper>

      <div className={styles.body}>
        <Wrapper paddingTop>
          <Steps
            data={stepsData}
            template="default"
            secondary
          />
        </Wrapper>
        <Wrapper classNames={styles.body_content}>
          <RichText
            content={parsedProductData.informationOne}
            siteData={siteData}
          />

          <RichText
            content={parsedProductData.requeriments}
            siteData={siteData}
          />
          <div className={styles.body_content_action}>
            <Button
              label={parsedProductData.formButtonTextb}
              primary
              link={parsedProductData.slug}
              source="landing-newpassport"
              dataQa={`home-${dataQaId}`}
              className={styles.body_content_button}
            />
          </div>

          <RichText
            content={parsedProductData.informationTwo}
            siteData={siteData}
          />

          <div className={styles.body_content_action}>
            <Button
              label={t("View Other Forms")}
              secondary
              link="/home"
              className={styles.body_content_button}
            />
          </div>
        </Wrapper>
      </div>
      <OurBenefits
        className={styles.ourBenefits}
        data={ourBenefitsData}
        template="default"
      />
    </Layout>
  );
};

export default HomeNewpassport;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulFormType(filter: {node_locale: {eq: $language}}) {
      nodes {
        name
      }
    }
  }
`;