import { useState, useMemo, useEffect } from "react";
import { GetSiteInfoHandler } from "../services/api/GetSiteInfo/GetSiteInfoHandler";
import { setCookies, getCookie } from "../components/utils/cookies";
import { windowIsExist } from "../components/utils";

const useGetSiteInfo = () => {
  const [siteInfo, setSiteInfo] = useState(getCookie("site_info") || null);
  const [isLoadingApiInfo, setIsLoadingApiInfo] = useState(true);
  const hasEmptyCookie = Object.keys(siteInfo).length === 0;

  useEffect(() => {
    if (!hasEmptyCookie) {
      setIsLoadingApiInfo(false);
    }
  }, [siteInfo, hasEmptyCookie]);

  const cachedSiteInfoData = useMemo(() => {
    const getSiteInfo = windowIsExist ? new GetSiteInfoHandler({}) : "";

    let data = {};

    if (windowIsExist && hasEmptyCookie) {
      getSiteInfo
        .customAction(data)
        .then((res) => {
          setCookies("site_info", res);
          setSiteInfo(getCookie("site_info"));
          setIsLoadingApiInfo(false);
        })
        .catch((err) => {
          console.error("Error =====> error catching site info", err);
        });
    }
  }, [hasEmptyCookie]);

  return {
    isLoadingApiInfo,
    cachedSiteInfoData,
  };
};

export default useGetSiteInfo;
